

.ready{
    border:none;
    padding: 0;
    margin: 0;
    background: none;
    background-color: #f7ebd8;;
    /* font-weight: bold; */
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
}